import {useEffect, useState} from 'react';

let showConfirm: any = null;
let _dismiss: any = null;


interface IProps {
  title: string,
  description: any,
  onConfirmButtonClicked: Function,
  onCancelButtonClicked?: Function,
  onNegativeButtonClicked?: Function,
  confirmButtonText?: string
  disableNegativeButton?: boolean
  cancelButtonText?: string
  negativeButtonText?: string,
  width?: number,
  height?: number,
  zIndex?: number
}


const useConfirmDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<IProps | undefined>(undefined);
  const [zIndex, setZIndex] = useState<number>(4000);


  const dismiss = () => {
    setIsOpen(false);
  }


  useEffect(() => {
    showConfirm = (props: IProps) => {
      setIsOpen(true);
      setData(props)
      setZIndex(props.zIndex ?? 4001)
    };
    _dismiss = (props: IProps) => {
      setIsOpen(false);
      setData(undefined)
    };

  }, []);


  return {
    isOpen,
    dismiss,
    data,
    zIndex
  };
};

export default useConfirmDialog;

export const showConfirmDialog = (props: IProps) => {
  if (showConfirm) {
    showConfirm(props);
  }
};

export const dismissConfirmDialog = () => {
  if (_dismiss) {
    _dismiss()
  }
}